<template>
    <v-card>
        <v-app-bar id="app-bar-primary" flat dense v-if="enableHeader">
            <v-toolbar-title><h5>{{title}}</h5></v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn id="arrow" icon @click="show = !show">
                    <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                </v-btn>
            </v-toolbar-items>
            <slot name="widget-header-action"></slot>
        </v-app-bar>
        <v-divider v-if="enableHeader"></v-divider>
        <v-expand-transition>
            <v-card-text :class="contentBg" v-show="show" id="responsivo">
                <v-container fluid fill-heigth>
                    <slot/>
                </v-container>
            </v-card-text>
        </v-expand-transition>
    </v-card>
</template>

<script>
    export default {
        name: 'BaseWidget',
        props: {
            title: {
                type: String
            },
            enableHeader: {
                type: Boolean,
                default: true
            },
            contentBg: {
                type: String,
                default: 'white'
            }
        },
        data: () => {
            return {
                show: false
            }
        }
    }
</script>

<style lang="scss">
#responsivo{
    
    @media only screen and (max-width: 768px) {
      /* For mobile phones: */
        height: 100%
    }
}

#app-bar-primary{
    background-color:#808080;
    color:white;
}


#arrow{
    color:white;
}



</style>
